* {
  margin: 0;
  padding: 0;
  --background-button: #3e87f8;
  --x-translate: 0;
  --y-translate: 0;
}
body {
  background-color: silver;
  font-family: "Inter", sans-serif;
}
