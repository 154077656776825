.main {
  background-color: #222222;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main button {
  width: 80px;
  height: 30px;
  margin: 0 10px 0 0;
  border-radius: 5px;
  border: none;
  background-color: var(--background-button);
  color: white;
  cursor: pointer;
}

.logo {
  width: 24px;
  height: 28px;
  margin-left: 10px;
}
.logo img {
  width: 100%;
  height: 100%;
}
