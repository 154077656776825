.main {
  display: grid;
  grid-template-columns: calc(100% - 210px) 210px;
  grid-template-rows: 50px calc(100vw - 50px);
}

.topBar {
  grid-column: 1/3;
  grid-row: 1/2;
}
.content {
  grid-column: 1/2;
  grid-row: 2/3;
}
.settingsBar {
  grid-row: 2/3;
  grid-column: 2/3;
}
