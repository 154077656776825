.main {
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}

.rangeSliders {
  display: flex;
  flex-direction: column;
  margin: 7px 30px 0 0;
  justify-content: space-around;
  height: 200px;
}

.function {
  font-size: 10px;
  display: flex;
  margin: 5px 10px 0 20px;
 
}

.replay {
  display: flex;
  margin: 15px 0 0 20px;
}
