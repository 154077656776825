.main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  font-size: 12px;
}
.name {
  font-size: 10px;
}
.value {
  width: 10px;
}
.main input[type="range"] {
  height: 2px;
  -webkit-appearance: none;
  margin: 0 5px;
  width: 110px;
  position: relative;
  border-radius: 10px;
}

.main input[type="range"]:focus {
  outline: none;
}
.main input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #e0e0e0;
  border-radius: 5px;
  border: 0px solid #ffffff;
}
.main input[type="range"]::-webkit-slider-thumb {
  cursor: ew-resize;
  border: 2px solid #a6a6a6;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  -webkit-appearance: none;
  margin-top: -5px;
  background-color: #ffffff;
}

.main input[type="range"]::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: #afafaf;
  border-radius: 10px;
  z-index: 0;
}

.main input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #e0e0e0;
  border-radius: 5px;
}
.main input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #ffffff;
  border: 2px solid #a6a6a6;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}
.main input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.main input[type="range"]::-ms-fill-lower {
  background: #e0e0e0;
  border-radius: 15px;
}
.main input[type="range"]::-ms-fill-upper {
  background: #e0e0e0;
  border-radius: 15px;
}
.main input[type="range"]::-ms-thumb {
  margin-top: 1px;
  border: 2px solid #afafaf;
  height: 12px;
  width: 12px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}
.main input[type="range"]:focus::-ms-fill-lower {
  background: #e0e0e0;
}
.main input[type="range"]:focus::-ms-fill-upper {
  background: #e0e0e0;
}

.main input[type="range"]::before {
  width: var(--range-width, 0);
}
